import { useEffect, useState } from 'react';
import CustomForm from './components/CustomForm'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Congrats from './components/Congrats';

function App() {

  const questionsAnswers = [   
    {
      id:1,
      question:"Cosa ti aspetti di trovare nella Newsletter? Quali argomenti vorresti leggere?",
      type:"textArea",
      answers:[]
    },
    {
      id:2,
      question:" Usi anche il gruppo telegram Strategia IT?",
      type:"multipleChoices",
      answers:[
        {id:1, text:"SI" },
        {id:2, text:"NO" }
      ]
    },
    {
      id:3,
      question:"Ascolti il podcast Strategia IT?",
      type:"multipleChoices",
      answers:[
        {id:1, text:"SI" },
        {id:2, text:"NO" }
      ]
    },
    {
      id:4,
      question:"Quali di queste opzioni ti descrive meglio?",
      type:"multipleChoices",
      answers:[
        {id:1, text:"Dipendente" },
        {id:2, text:"Startupper" },        
        {id:3, text:"Libero Professionista" },
        {id:4, text:"Altro" }
      ]
    },
    {
      id:5,
      question:"Vuoi rimanere anonimo?",
      type:"multipleChoices",
      answers:[
        {id:1, text:"Si" },
        {id:2, text:"No mi piace farti sapere chi sono" }
      ]
    },
    {
      id:6,
      question:"Hai capito di cosa mi occupo?",
      type:"multipleChoices",
      answers:[
        {id:1, text:"NO" },
        {id:2, text:"SI provo ad indovinare" }
      ]
    },
    {
      id:7,
      question:"Ti lascio uno spazio se vuoi aggiungere altro",
      type:"textArea",
      answers:[]
    }
  ]
  const [survey, setSurvey] =useState([])

  useEffect(() =>{
    setSurvey(questionsAnswers)
    console.log("valore di survey",survey)
  },[])
  return (
    <div className="App">
      <Router>
      <Switch>
          <Route path="/thankyou">
            <Congrats />
          </Route>                
          <Route path="/">
            <CustomForm/>
          </Route>
        </Switch>
        
      </Router>      
    </div>
  );
}

export default App;
