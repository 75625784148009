import React,{useState} from 'react'
import { Controller, useForm } from "react-hook-form"
import './CustomForm.css'
import {RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  TextareaAutosize} from  "@material-ui/core"

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Axios from 'axios'

const I_URL = process.env.REACT_APP_I_URL || 'no_url'

const schema = yup.object().shape({
  question1: yup.string().required('Inserisci la risposta alla domanda'),
  question2: yup.string().required('Inserisci la risposta alla domanda'),
  question2_no: yup.string().when("question2", {
    is: "NO",
    then: yup.string().required('Scrivimi perché non usi il gruppo Telegram'),
    otherwise: yup.string().notRequired()
  }),
  question3: yup.string().required('Inserisci la risposta alla domanda'),
  question3_no: yup.string().when("question3", {
    is: "NO",
    then: yup.string().required('Scrivimi come mai non ascolti il podcast, unico nel panorama del settore IT'),
    otherwise: yup.string().notRequired()
  }),
  question4: yup.string().required('Inserisci la risposta alla domanda'),
  question4_altro: yup.string().when("question4", {
    is: "Altro",
    then: yup.string().required('Fai altro? scrivimelo grazie'),
    otherwise: yup.string().notRequired()
  }),
    
  question5: yup.string().required('Inserisci la risposta alla domanda'),
  question5_si: yup.string().when("question5", {
    is: "SI",
    then: yup.string().required('Devi indovinare su..'),
    otherwise: yup.string().notRequired()
  }),
  question6: yup.string().notRequired(),
});

export default function CustomForm(){

  const [viewQ2NO,disableQ2NO] = useState(true)
  const [viewQ3NO,disableQ3NO] = useState(true)
  const [viewQ4NO,disableQ4NO] = useState(true)
  const [viewQ5SI,disableQ5SI] = useState(true)
  const {register, setValue,errors ,control,handleSubmit} = useForm({
    resolver: yupResolver(schema)
  });
  const [data, setData] = useState([]);  
  const handleChange2 = (event) =>{
    const { value } = event.target;    
    disableQ2NO(value !== 'NO')    
  }
  const handleChange3 = (event) =>{
    const { value } = event.target;    
    disableQ3NO(value !== 'NO')  
  }
  const handleChange4 = (event) =>{
    const { value } = event.target;    
    disableQ4NO(value !== 'Altro')    
  }
  const handleChange5 = (event) =>{
    const { value } = event.target;    
    disableQ5SI(value !== 'SI')    
  }  
  const onSubmit = data => {
    Axios.post(I_URL,data)
    .then(res => {
      window.location.assign("/thankyou");
    })
  }
  return (
    <div>
    <h1>Newsletter Feedback</h1>
    <form className="form" onSubmit={handleSubmit(onSubmit)}>   
        <FormControlLabel
        labelPlacement="top"
        label="* 1. Cosa ti aspetti di trovare nella Newsletter? Quali argomenti vorresti leggere?"
        control={
          <div>
            <TextareaAutosize 
              className="TextareaAutosize"
              rowsMin={10} 
              name="question1" 
              placeholder="Scrivi quali argomenti preferisci, se non ti basta lo spazio allargami quanto vuoi"
              ref={register}/>
          </div>
        }        
        /> 
        {errors.question1 && <p>{errors.question1.message}</p>}
        
        <FormControlLabel
        labelPlacement="top"
        label="* 2. Usi anche il gruppo telegram Strategia IT?"
        control={
          <div>
          <label htmlFor="question2_SI">SI</label>
          <input type="radio" 
          name="question2"
          value="SI"
          id="question2_SI"
          ref={register}
          onChange={handleChange2}
          />
          <label htmlFor="question2_NO">NO</label>
          <input type="radio" 
          name="question2"
          value="NO"
          id="question2_NO"
          ref={register}
          onChange={handleChange2}
          
          />
          </div>
        }
        />   
        {!viewQ2NO && <FormControlLabel
          className="centered"          
          control={
            <TextareaAutosize 
            rowsMin={5} 
            name="question2_no" 
            ref={register} 
            placeholder="* Come mai no? Allargami quanto vuoi" />            
          }
        />
        }
        {errors.question2 && <p>{errors.question2.message}</p>}
        {errors.question2_no && <p>{errors.question2_no.message}</p>}
        
        <FormControlLabel 
        label="* 3. Ascolti il podcast Strategia IT?"
        labelPlacement="top"
        control={
          <div>
          <label htmlFor="question3_SI">SI</label>
          <input type="radio" 
          name="question3"
          value="SI"
          id="question2_SI"
          ref={register}
          onChange={handleChange3}
          />
          <label htmlFor="question3_NO">NO</label>
          <input type="radio" 
          name="question3"
          value="NO"
          id="question2_NO"
          ref={register}
          onChange={handleChange3}
          
          />
          </div>
        }
        />
        {!viewQ3NO && <FormControlLabel
          className="centered"          
          control={            
            <TextareaAutosize rowsMin={5} name="question3_no" ref={register} placeholder="* Come mai no? Allargami quanto vuoi" />            
          }
        />
        }
        {errors.question3 && <p>{errors.question3.message}</p>}
        {errors.question3_no && <p>{errors.question3_no.message}</p>}
        

        <FormControlLabel 
        label="* 4. Quali di queste opzioni ti descrive meglio?"
        labelPlacement="top"
        control={
          <div className="container_radio">
          <div>
          <label htmlFor="question4_Dipendente">Dipendente</label>
          <input type="radio" 
          name="question4"
          value="Dipendente"
          id="question4_Dipendente"
          ref={register}
          onChange={handleChange4}
          />
          </div>
          <div>
          <label htmlFor="question4_Startupper">Startupper</label>
          <input type="radio" 
          name="question4"
          value="Startupper"
          id="question4_Startupper"
          ref={register}
          onChange={handleChange4}
          
          />
          </div>
          <div>
          <label htmlFor="question4_CXO">C-level</label>
          <input type="radio" 
          name="question4"
          value="C-level"
          id="question4_CXO"
          ref={register}  
          onChange={handleChange4}        
          />
          
          </div>
          <div>
          <label htmlFor="question4_Libero">Libero Professionista</label>
          <input type="radio" 
          name="question4"
          value="Libero Professionista"
          id="question4_Libero"
          ref={register}
          onChange={handleChange4}
          />
          </div>
          <div>
          <label htmlFor="question4_Altro">Altro</label>
          <input type="radio" 
          name="question4"
          value="Altro"
          id="question4_Altro"
          ref={register}
          onChange={handleChange4}          
          />
          </div>
          
          </div>
        }
        />

        {!viewQ4NO && <FormControlLabel
          className="centered"
          control={            
            <TextareaAutosize rowsMin={5} name="question4_altro" ref={register} placeholder="* Cosa intendi per altro? Allargami quanto vuoi" />  
          }
        />
        }
        {errors.question4 && <p>{errors.question4.message}</p>}
        {errors.question4_altro && <p>{errors.question4_altro.message}</p>}

        <FormControlLabel 
        label="* 5. Hai capito di cosa mi occupo?"
        labelPlacement="top"
        control={
          <div>
          <label htmlFor="question5_NO">NO</label>
          <input type="radio" 
          name="question5"
          value="NO"
          id="question5_NO"
          ref={register}
          onChange={handleChange5}
          />
          <label htmlFor="question5_NO">SI provo ad indovinare</label>
          <input type="radio" 
          name="question5"
          value="SI"
          id="question5_SI"
          ref={register}
          onChange={handleChange5}          
          />
          </div>
        }
        />
       {!viewQ5SI && <FormControlLabel
          className="centered"          
          control={
            <TextareaAutosize rowsMin={5} name="question5_si" ref={register} placeholder="* Digita qui e allargami quanto vuoi" />  
          }
        />
       }
        {errors.question5 && <p>{errors.question5.message}</p>}
        {errors.question5_si && <p>{errors.question5_si.message}</p>}
        
        <FormControlLabel
        labelPlacement="top"
        label="6. Ti lascio uno spazio se vuoi aggiungere altro"
        control={
          <div>
          <TextareaAutosize 
          className="TextareaAutosize"
          rowsMin={10} 
          placeholder="Dimmi tutto e allargami quanto vuoi"
          name="question6" ref={register}/>
          </div>
        }        
        /> 
        <div>
        <input type="submit" value="Invia" className="button"/>
        </div>              
    </form>
    </div>
  )
}   