import React from 'react'
import Yep from '../images/yep.png'
import './Congrats.css'

export default function Congrats(){
  return(
    <div className="view">
      <div className="container">
        <img className="thankYou" src={Yep} />
        <p className="tagline">
          Fantastico! Potrò migliorare i contenuti della Newsletter.
          Buone Feste!
        </p>
      </div>
      <div className="actions">
        <a className="button" href="https://refacturing.com" target="_blank">Learn more about Refacturing</a>
        <p className="description">
          Grazie per aver dato un feedback
        </p>
      </div>
    </div>
  )
}